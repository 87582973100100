export enum Environment {
	Production = 'production',
	Staging = 'staging',
	Acceptance = 'acceptance',
	Local = 'local',
}

export enum ApplicationModule {
	Internal = '/internal',
}

export enum Template {
	mobile = 'mobile',
	desktop = 'desktop',
}

export enum StorageKey {
	user = 'user',
	menu = 'menu',
	token = 'token',
	refreshToken = 'refreshToken',
	userDetails = 'userDetails',
}

export enum MessageBoxKey {
	clientOrigins = 'clientOrigins',
	cancellationReasons = 'cancellationReasons',
}

export enum NavigationType {
	modal,
	popover,
	page,
}

export enum ErrorType {
	Toast = 'toast',
	PermanentToast = 'permanentToast',
	Component = 'component',
	Validation = 'validation',
}

export enum NavigationDataKey {
	// GLOBAL
	action = 'action',
	queryParams = 'queryParams',
	message = 'message',
	//FORMS
	eventForm = 'eventForm',
	reservationForm = 'reservationForm',
	appointmentForm = 'appointmentForm',
	deviceForm = 'deviceForm',
	cancelForm = 'cancelForm',
	permissionsForm = 'permissionsForm',
	// MODELS
	address = 'address',
	eventType = 'eventType',
	deviceType = 'deviceType',
	clientOrigin = 'clientOrigin',
	location = 'location',
	event = 'event',
	calendarEvent = 'calendarEvent',
	reservation = 'reservation',
	eventAttendanceType = 'eventAttendanceType',
	loan = 'loan',
	device = 'device',
	devices = 'devices',
	client = 'client',
	employee = 'employee',
	date = 'date',
	beegoAppointment = 'beegoAppointment',
	calendarEventReservationEnd = 'calendarEventReservationEnd',
	questionCategory = 'questionCategory',
	digibankAgency = 'digibankAgency',
	targetAudience = 'targetAudience',
	template = 'template',
	templateVariables = 'templateVariables',
	lesson = 'lesson',
	lessonIndex = 'lessonIndex',
	waitListItem = 'waitListItem',
	cancellationReason = 'cancellationReason',
	eventId = 'eventId',
}

export enum Referer {
	ReservationComponent = 'ReservationComponent',
}

export enum ValidationErrorLevel {
	warning = 'warning',
	error = 'error',
}

export enum UserPermissions {
	ReadConfigurations = 'read-configurations',
	WriteConfigurations = 'write-configurations',
	ReadClientsSocial = 'read-clients-social',
	ReadClientsHistory = 'read-clients-history',
	ListClients = 'list-clients',
	ExportClients = 'export-clients',
	MergeClients = 'merge-clients',
	DeleteClients = 'delete-clients',
	ReadEmployees = 'read-employees',
	WriteEmployees = 'write-employees',
	WriteDevices = 'write-devices',
	ReadLoans = 'read-loans',
	WriteLoans = 'write-loans',
	WriteEvents = 'write-events',
	ExportEvents = 'export-events',
	ReadStatistics = 'read-statistics',
	WriteTenants = 'write-tenants',
	WriteUsers = 'write-users',
	ExportCalendarEvents = 'export-calendar-events',
	ExportAttendances = 'export-attendances',
	WriteSupport = 'write-support',
	WriteWaitList = 'write-wait-list',
}

export enum Action {
	UPDATE = 'update',
	CREATE = 'create',
	DELETE = 'delete',
	SELECT = 'select',
}

export enum InputMode {
	TEXT = 'text',
	DECIMAL = 'decimal',
	NUMERIC = 'numeric',
	TEL = 'tel',
	SEARCH = 'search',
	EMAIL = 'email',
	URL = 'url',
}

export enum FileType {
	Pdf = 'application/pdf',
	Image = 'image/*',
}

export enum DigibankActivity {
	Loan = 'uitlenen',
	GroupEducation = 'klassikale opleiding',
	PersonalEducation = '1-op-1 opleiding',
	WalkIn = 'inloopvraag',
	ELearning = 'e-learning',
}

export enum DigibankObjective {
	Loan = 'uitleendienst (d1)',
	Education = 'opleiding (d2)',
	EssentialService = 'essentiële diensten (d3)',
	None = 'none',
}

export enum Gender {
	Man = 'man',
	Woman = 'vrouw',
	NonBinary = 'non-binair',
}

export enum AgeCategory {
	MinusEighteen = '-18',
	PlusEighteen = '18-29',
	PlusThirty = '30-54',
	PlusFiftyFive = '55+',
}

export enum StatisticScale {
	Day = 'day',
	Week = 'week',
	Month = 'month',
	Year = 'year',
}

export enum BeegoAppointmentStatus {
	Finished = 'finished',
	Cancelled = 'cancelled',
	Todo = 'todo',
	Unknown = 'unknown',
	Queue = 'queue',
	Unfinished = 'unfinished',
}

export enum PaymentMethod {
	DirectDebit = 'Domiciliëring',
	SumUp = 'SumUp',
	Transfer = 'Overschrijving',
	Qr = 'QR-code',
	Cash = 'Cash',
	GiftVoucher = 'Cadeaubon',
	Digicheque = 'Digicheque',
}

export enum WaitListItemStatus {
	Requested = 'requested',
	Dismissed = 'dismissed',
	Resolved = 'resolved',
}
