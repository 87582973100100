import { IACL } from './interfaces';
import { UserPermissions } from './enums';
import { isEmpty } from 'lodash';
import { AppModule } from '@app/app.module';
import { UserManager } from '@shared-managers/user.manager';
import { VALIDATION } from './constants';

export const ACL: IACL = {
	internal: {
		routes: {
			start: ['*'],
			kalender: ['*'],
			activiteiten: ['*'],
			uitleendienst: [UserPermissions.ReadLoans],
			wachtlijst: [UserPermissions.WriteWaitList],
			clienten: ['*'],
			'clienten/client': ['*'],
			'clienten/samenvoegen': [UserPermissions.MergeClients],
			medewerkers: [UserPermissions.ReadEmployees],
			'medewerkers/medewerker': [UserPermissions.ReadEmployees],
			administratie: [UserPermissions.ReadConfigurations],
			statistieken: [UserPermissions.ReadStatistics],
			hulpmiddelen: ['*'],
			digibank: [UserPermissions.WriteTenants],
			'digibank/template': [UserPermissions.WriteTenants],
			'digibank/permission': [UserPermissions.WriteTenants],
		},
	},
};

/**
 * Validate a route & user permission against the ACL
 * @param route The route the user want's to navigate to
 * @returns Whether the user is allowed or not
 */
export const validateACL = (route: string): boolean => {
	const userManager = AppModule.injector.get(UserManager);
	const routes = route.split('/').filter((_route) => !isEmpty(_route));
	const module = routes.shift();

	if (VALIDATION.UUID.test(routes[routes.length - 1])) {
		routes.pop();
	}

	const formattedRoute = routes.join('/');
	const acl = ACL[module];
	const requiredPermissionsForChild = acl.routes[formattedRoute];

	if (
		!requiredPermissionsForChild?.includes('*') &&
		userManager.hasNotAllPermissions(requiredPermissionsForChild as UserPermissions[])
	) {
		return false;
	}
	return true;
};
