import { DigibankObjective } from '@shared-libs/enums';
import { ICalendarEvent } from '@shared-models/calendar-event.model';
import { IUser } from '@shared-models/user.model';
import { IDigibankAgency } from './digibank-agency.model';
import { IEmployee } from './employee.model';
import { IEventType } from './event-type.model';
import { ILocation } from './location.model';
import { IReservation } from './reservation.model';
import { ILesson } from './lesson.model';
import { IWaitListItem } from './wait-list-item.model';

export enum EventInterval {
	OneTime = 'one-time',
	Daily = 'daily',
	BusinessDays = 'business-days',
	Weekly = 'weekly',
	Monthly = 'Monthly',
	MonthlyDay = 'monthly-day',
	Yearly = 'yearly',
	Custom = 'custom',
}

export enum CustomIntervalType {
	Days = 'days',
	BusinessDays = 'business-days',
	Weeks = 'weeks',
	Months = 'months',
	Years = 'years',
}

/**
 * A type of the even attendance
 *
 * Reservation: reservation of a set time slot
 * Registration: reservation without a set time slot
 * Walk In: no reservation, come whenever
 */
export enum EventAttendanceType {
	Reservation = 'reservation',
	Registration = 'registration',
	WalkIn = 'walk-in',
	Lessons = 'lessons',
}

export enum EventLocationType {
	OnLocation = 'on-location',
	Online = 'online',
}

export interface IEvent {
	id: string;
	name: string;
	description: string;
	color: string;
	startsAt: Date;
	endsAt: Date;
	intervalEndsAt: Date;
	interval: EventInterval;
	customIntervalType: CustomIntervalType;
	customIntervalNumber: number;
	attendanceType: EventAttendanceType;
	reservationInterval: number;
	maxRegistrations: number;
	isLoanLocation: boolean;
	createdAt: string;
	digibankObjective: DigibankObjective.Education | DigibankObjective.EssentialService | DigibankObjective.None;
	locationType: EventLocationType;
	url?: string;
	EventType?: IEventType;
	EventTypeId?: string;
	Location?: ILocation;
	LocationId?: string;
	DigibankAgencyId?: string;
	DigibankAgency?: IDigibankAgency;
	CreatedBy: IUser;
	CalendarEvent: ICalendarEvent;
	CalendarEvents: Array<ICalendarEvent>;
	Reservations: Array<IReservation>;
	Employees: Array<IEmployee | string>;
	Lessons: Array<ILesson>;
	WaitListItems: Array<IWaitListItem>;
	replyTo: string;
	telephone: string;
	isPublicEvent: boolean;
	publicInfo: string;
}
