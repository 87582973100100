import { Injectable } from '@angular/core';

/* TODO: replace with redux strategy? */

type StateData = string | number | { [key: string]: any };

/**
 * The manager for state management. This manager is used to store the state of objects, mainly used to save the state of a data display component.
 *
 *  The state object is a object to which you can add properties, and can be updated when the component using the state object is destroyed
 */
@Injectable({
	providedIn: 'root',
})
export class StateManager {
	private state: { [key: string]: StateData } = {};

	/**
	 * Add data to the state data property.
	 * @param key The key to store the data
	 * @param {StateData} data The data to store
	 */
	public setState(key: string, data: StateData): void {
		this.state = { ...this.state, ...{ [key]: data } };
	}

	/**
	 * Get data by the key from the state data
	 *
	 * It is possible to pass a type of the returning data.
	 * @param key The key linked to the data when adding the data
	 * @returns The data linked to the key
	 */
	public getState<Model = StateData>(key: string): Model {
		return this.state[key] as any;
	}

	/**
	 * Flush the entire navigation state store
	 */
	public flush(): void {
		this.state = {};
	}
}
